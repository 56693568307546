/* eslint-disable */
import {useEffect, useRef, useState} from 'react';
import QrScanner from 'qr-scanner';
import QrFrame from '../../assets/qr-frame.svg';
import './QrStyles.css';
import {startCharging} from '../startPage/startCharging';
import {useTranslation} from 'react-i18next';
import {Button} from "../../shared/ui";
import {useNavigate} from "react-router-dom";

const ScannerPage = () => {
  const scanner = useRef(null);
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const [qr0n, setQrOn] = useState(true);
  //eslint-disable-next-line
  const [error, setError] = useState(null);
  let navigate = useNavigate();
  const {openPaymentLink} = startCharging();
  const [isScanSuccess, setIsScanSuccess] = useState(false);
  const [qrData, setQrData] = useState(null);

  const {t} = useTranslation();

  const INITIAL_KWT = 44;
  const autoStart = true;

  useEffect(() => {
    window.scrollTo(0, 60);
  }, []);


  const onScanSuccess = qrResult => {
    setQrData(qrResult.data);
    setIsScanSuccess(true);
  };

  const onScanFail = error => {
    console.error(error);
  };

  useEffect(() => {
    const MoveToGenerateCheckoutUrl = async url => {
      // eslint-disable-next-line
      const [protocol, unusedSpace, domain, path] = url.split('/');
      // eslint-disable-next-line
      const [urlPathData, stationNumber] = path.split('=');
      await openPaymentLink(stationNumber, INITIAL_KWT,
          autoStart, setError);
      navigate(`/${path}`);
      scanner.current.stop();
    };
    if (isScanSuccess) {
      MoveToGenerateCheckoutUrl(qrData);
    }

    let isMounted = true;
    const video = videoEl.current;

    if (video && !scanner.current) {
      scanner.current = new QrScanner(videoEl.current, onScanSuccess, {
        onDecoderError: onScanFail,
        preferredCamera: 'environment',
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl?.current || undefined,
      });
    }

    scanner?.current
    ?.start()
    .then(() => {
      if (isMounted) {
        setQrOn(true);
      }
    })
    .catch(err => {
      if (err && isMounted) {
        console.log('error');
        setQrOn(false);
      }
    });

    if (!qr0n) {
      alert(t('qrScanner.cameraPermissionAlert'));
    }

    scanner.current.turnFlashOn()

    return () => {
      scanner?.current?.stop();
      isMounted = false;
    };

    // eslint-disable-next-line
  }, [qrData, qr0n]);

  return (
      <div className={'qr-container'}>
        <div className={'qr-reader'}>
          <video ref={videoEl}></video>
          {
            <div ref={qrBoxEl} className={'qr-box'}>
              <img src={QrFrame} alt="QR frame" width={256} height={256}
                   className={'qr-frame'}/>
            </div>
          }
        </div>
        <div className={'button-container'}>
          <Button
              onClick={() => navigate(-1)}
              variant="outline"
              style={{
                display: "flex",
                gap: "12px",
                margin: "45px auto",
                width: "86%"
              }}
          >
        <span
            style={{
              fontSize: "24px",
              fontWeight: "300",
              letterSpacing: "2px"
            }}>
{t('qrScanner.backButton')}
        </span>
          </Button>
        </div>
      </div>
  );
};

export default ScannerPage;
